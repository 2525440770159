

const getAuthorizationStatus = (t, options) => {
    return new TrelloPowerUp.Promise((resolve) => {
        t.loadSecret("authToken")
            .then(resolve({authorized: true}))
            .catch(resolve({authorized: false}))
    });
}

const showAuthorization = (t, options) => {
    return t.popup({
        title: 'Authorize Timeular Account',
        url: './auth.html',
        height: 140,
    });
}

// remove personal data
const removePersonalData = (t, options) => {
    return Promise.all([
        t.clearSecret("apiKey"),
        t.clearSecret("apiSecret"),
        t.clearSecret("authToken")
    ]);
}

//Buttons to show
const getCardButtons = (t, options) => {
    console.log("getCardButtons called");
    let START_ICON = process.env.URL + "/img/trello2timeular-icon.png";
    let cardButtons = [];

    const startTrackingButton = {
        icon: START_ICON,
        text: "Start Tracking",
        condition: "edit"
    }

    cardButtons.push(startTrackingButton);

    return cardButtons;
}

TrelloPowerUp.initialize({
    'remove-data': removePersonalData,
    'authorization-status' : getAuthorizationStatus,
    'show-authorization' : showAuthorization,
    'card-buttons': getCardButtons
});
